
.Accordian_space {position: relative;
    max-width: 1200px;
    border-radius: 24px;
    background-color: #307aa0;margin: 150px auto 50px auto;
    padding: 80px 150px;}

.Accordian_space .accordion-item {border: solid 1px #e2e2e2;
    border-radius: 6px;
    margin-bottom: 20px;background-color: transparent;}

.Accordian_space .accordion-item .accordion-button {background-color: transparent;font-family: "Potta One";
    text-align: left;
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    padding: 15px 30px;box-shadow: none;}

.Accordian_space .accordion-item .accordion-collapse .accordion-body {padding: 15px 30px;
    margin: 0;
    font-family: "Potta One";
    color: rgb(255 255 255);
    line-height: 24px;
    text-align: left;
    opacity: 0.9;
    font-weight: 400;
    font-size: 14px;border-top: 1px solid #fff;}

.Accordian_space .accordion-item .accordion-button::after {content: "+";background-image: none;transition: none;display: flex;
    align-items: center;
    justify-content: center;}

.Accordian_space .accordion-item .accordion-button:not(.collapsed)::after {background-image: none;content: "-";transform: none;}
.Accordian_space .accordion-item:not(:first-of-type) {
    border-top: solid 1px #e2e2e2;
}

.Accordian_space .accordion-item .accordion-collapse .accordion-body a {
    color: #fff;
}

@media (max-width:1024px) { 
    .Accordian_space {
        padding: 50px;
    }
 }
@media (max-width:767px) {
    .Accordian_space {
        padding: 30px 20px;
        margin: 80px auto 50px auto;
    }
    .Accordian_space .accordion-item .accordion-button {
        font-size: 15px;
        padding: 10px 15px;
    }
    
}

@media (max-width:440px) {
    .Accordian_space {
        padding: 30px 20px;
    }
    .Accordian_space .accordion-item .accordion-button {
        font-size: 15px;
        padding: 10px 15px;
    }
}