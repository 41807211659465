
.Footer_main {
    background-color: #020202;
    text-align: center;
    padding: 60px 0px 30px 0px;
    margin-top: 150px;
    position: relative;
    &:before {
        background-image: url(../../Static/FooterTopbg.png);
        background-size: 100%;
        content: "";
        top: 0px;
        position: absolute;
        width: 100%;
        height: 200px;
        left: 0px;
        right: 0px;
        top: -85px;
        background-repeat: no-repeat;
    }
    .Footer-logo {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0px auto;
        overflow: hidden;
        background-color: #cbcbcb;
        border-radius: 50%;
        img {
            position: absolute;
            width: 100%;
            left: 0px;
            right: 0px;
            transition: all 0.2s;
            z-index: 9;
        }
        .footerlogo_image2 {    opacity: 0;}
        .footerlogo_image3 {
            top: 17px;
            width: 152px;
            margin-left: -5px;
            opacity: 0;
            margin: 0px auto;
        }
        .footerlogo_image5 {opacity: 0;}
    }
 .Footer-logo:hover .footerlogo_image2 { opacity: 0; }
    .Footer-logo:hover .footerlogo_image3 {  opacity: 1; }
    .Footer-logo:hover .footerlogo_image3 {
        animation-name: rotate;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    .Footer-logo:hover .footerlogo_image4 {  opacity: 0; }
    .Footer-logo:hover .footerlogo_image5 {  opacity: 1; }
    .Footer-logo:hover img.footerlogo_image1 {
        opacity: 0;
    }
}
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.footer_links {
    text-align: center;
    ul {
        list-style: none;
        padding: 0px;
        display: flex;
        justify-content: center;
        li {
            padding: 0px 15px;
            a {
                position: relative;
                color: #fff;
                text-decoration: none;font-size: 18px;font-family: 'Potta One', sans-serif;
                &:hover {
                    color: #ffb800;
                }
            }
        }
    }
}


.footer_Copyright {
    margin-bottom: 30px;
    p {margin-top: 40px;
        margin-bottom: 0px;
        font-family: 'Potta One', sans-serif;
        color: hsla(0, 0%, 100%, 0.6);
    }
}

.Footer_main {
    .SocialList {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 10px 3px;
        padding: 7px;display: inline-block;margin: 45px 0px 30px 0px;
        ul {
            list-style: none;padding: 0;
            margin: 0;
            align-items: center;
            display: flex;
            li {
                list-style: none;
                padding: 0px 7px;
            }
        }
    }
}

@media (max-width:767px) {
    .footer_links ul {
        flex-wrap: wrap;
    }
}