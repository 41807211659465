@import url('https://fonts.googleapis.com/css2?family=Potta+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Piedra&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Potta One', cursive;

}

body, * {
  cursor: url(./Static/Cursor_arrow.png), auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body > iframe {
  display: none;
}


.Minting_miansection .MuiButton-containedPrimary{
  position: relative;
}



.Minting_miansection .connectedWallet .MuiPaper-root {
    background-color: #307aa0 !important;
    padding: 30px 40px !important;
}

.Minting_miansection .connectedWallet .MuiPaper-root button.MuiButton-contained {
    background-color: #fff !important;
    background: linear-gradient(180deg,#fff 0%,#fff 100%);
    color: #000;
    font-family: "Potta One", sans-serif;
    font-size: 22px;
}

.Minting_miansection .MuiButton-containedPrimary:before {
  content: "";
  background: url(Static/btnIndect.svg);
  background-size: cover;
  width: 20px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 28px;
  animation: flash linear 0.2s infinite;
}

@-webkit-keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}

.Minting_miansection .MuiButton-containedPrimary:hover {
  background: #ffb800;
  box-shadow: 0px 13px 0px #cb9509;
}


@media (max-width:767px) {
  .Minting_miansection .MuiButton-containedPrimary {
    font-size: 36px;
  }
}
@media (max-width:440px) {
  .Minting_miansection .MuiButton-containedPrimary {
    font-size: 26px;
  }
}