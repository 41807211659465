
.header-navbar {
    .ml-auto-header {
        margin-left: auto;
        margin-right: auto;
        .nav-link {
            color: #fff;
        }
    }
    .SocialList {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px 3px;
        padding: 7px;
        ul {
            padding: 0;
            margin: 0;
            align-items: center;
            display: flex;
            li {
                list-style: none;
                padding: 0px 7px;
                // a {
                //     img {

                //     }
                // }
            }
        }
    }
}
.header-navbar.navbar-light  .ml-auto-header .nav-link {
    color: #fff;
    font-size: 22px;
    padding: 0px 20px;
    &:hover {
        color: #ffb800;
    }
}

@media (max-width:990px) { 
    body.fixmenu {
        overflow: hidden;
        .header-navbar .navbar-collapse.collapse {
            height: 100% !important;
        }
    }
    .header-navbar button.navbar-toggler {
        background-color: #4ba7d6;
        padding: 6px 6px;
    }
    .header-navbar .navbar-collapse {position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 60px;
        background-color: #1a1a1a;
        overflow: hidden;
        z-index: 999999999;
        transition: all .2s;
        height: 0!important;}
    
        
        
    .header-navbar .navbar-collapse.collapse .navbar-nav {margin-bottom: 20px;}
    .header-navbar .navbar-collapse.collapse .navbar-nav a.nav-link {padding: 8px 15px;}
    .header-navbar .SocialList {max-width: 190px;margin-left: 15px;}
 }