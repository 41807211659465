
.Topbannerslider_space {
    margin: 50px auto;
    max-width: 1000px;
    position: relative;
    .slider_images {
        text-align: center;
    }
    .react-multiple-carousel__arrow--left {
        background-image: url(../../Static/Arrow_left.svg);
        width: 100px;
        height: 73px;
        background-color: transparent;
        background-position: 0% 00%;
        &:before {
            display: none;
        }
        &:hover {
            background: transparent;
            background-image: url(../../Static/Arrow_left.svg);
            background-position: 0% 100%;
        }
    }
    .react-multiple-carousel__arrow--right {
        background-image: url(../../Static/Arrow_right.svg);
        width: 100px;
        height: 73px;
        background-color: transparent;
        background-position: 0% 100%;
        &:before {
            display: none;
        }
        &:hover {
            background: transparent;
            background-image: url(../../Static/Arrow_right.svg);
            background-position: 0% 0%;
        }
    }
    .react-multi-carousel-list {
        padding: 0px 0px 0px 0px;
        .react-multiple-carousel__arrow--right, 
        .react-multiple-carousel__arrow--left {
            display: none;
        }
    }

    .carousel-button-group {
        position: absolute;
        width: 100%;
        left: 0px;
        top: 38%;
        right: 0px;
        .react-multiple-carousel__arrow--left {
            left: -100px;
        }
        .react-multiple-carousel__arrow--right {
            right: -100px;
        }
    }
    
}

@media (max-width:1024px) {
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--left {
        left: -30px;
    }
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--right {
        right: -30px;
    }
}
@media (max-width:990px) {
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--left {
        left: -10px;
    }
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--right {
        right: -10px;
    }
}
@media (max-width:767px) {
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--left {
        left: -10px;
    }
    .Topbannerslider_space .carousel-button-group .react-multiple-carousel__arrow--right {
        right: -10px;
    }
}