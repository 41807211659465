.Darkbg_color {
    background-color: #1A1A1A;
}

.background-topimage {
    background-image: url(../../Static/TopBackground_shap.svg);
    background-size: cover;
    background-position: center;

    .Topbanner_space {
        padding: 80px 0px 100px 0px;
    }
}

.Minting_miansection {
    margin-top: -80px;
    .MuiContainer-maxWidthXs {
        max-width: 564px;
        .MuiPaper-root {
            padding: 0px;
            background-color: transparent;
            box-shadow: none;
            border-radius: 20px;
        }
    }
    .MuiContainer-root.MuiContainer-maxWidthLg {
        margin: 0px auto !important;
    }
    .MuiContainer-maxWidthXs .MuiPaper-root {
        padding: 0px !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border-radius: 20px !important;
    }
}


@media (max-width:767px) {
    .background-topimage {
        .Topbanner_space {
            padding: 40px 0px 100px 0px;
        }
    }
}