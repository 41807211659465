.centerheading_main {
    text-align: center;
    max-width: 820px;
    margin: 0px auto;
    h2 {
        font-weight: 400;
        font-size: 60px;
        line-height: 78px;
        font-family: 'Piedra', cursive;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        font-family: Roboto, sans-serif;
    }
}

.centercolorwhite {
    h2 {
        color: #fff;
    }
    p {
        color: rgb(255 255 255 / 84%);
    }
}

.centercoloryellow {
    h2 {
        font-family: 'Potta One', sans-serif;
        color: #ff0;
        text-shadow: 2px 3px 2px rgb(0 0 0 / 55%);
        font-size: 50px;
        line-height: 77px;
        margin-bottom: 30px;
    }
}